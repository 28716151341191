.LoadingScreen {
  height: 100vh;
  width: 100vw;
  background: #ecf0f1;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-grow: inherit;
  overflow: hidden;

  img {
    align-self: center;
    animation: heartbeat 1s infinite;
    width: 200px;
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(.75);
  }
  100% {
    transform: scale(.75);
  }
}
