.float-label {
  position: relative;
  margin-bottom: 20px;
}

.label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 15px;
  transition: 0.2s ease all;
}

.label-float {
  top: 6px;
  font-size: 14px !important;
}
