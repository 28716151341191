html,
body {
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

label {
  font-size: 20px !important;

  &::before {
    content: none !important;
  }
}

.am-navbar {
  z-index: 9;
  position: relative;
}

.am-drawer-sidebar {
  background: #FFF;
  width: 100%;
}

.ant-input,
.ant-picker,
.ant-input-number {
  padding: 25px 10px 10px !important;
  font-size: 18px !important;
  width: 100% !important;
}


.ant-input-number-input-wrap .ant-input-number-input,
.ant-input-password.ant-input-affix-wrapper {
  padding: 0 !important;
}

.ant-input-password .ant-input-suffix {
  margin-right: 12px;
}


.ant-form-item-extra {
  color: #373737 !important;
  font-style: italic;
  font-weight: bold !important;
}

.ant-layout .kom-je-kijken {
  &__signup {
    &__container {
      padding: 40px;
      background: #FFF;

      label {
        font-size: 20px;

        &::before {
          content: none !important;
        }
      }

      .ant-input {
        padding: 10px 15px;
        font-size: 18px;
      }
    }

    &__splashimage {
      img {
        display: block;
        width: 100vw;
        height: 100vh;
        object-fit: cover;
      }
    }
  }
}

.placeholder {
  background-color: #ebebef;
  color: #bbb;
  text-align: center;
  height: 30px;
  line-height: 30px;
  width: 100%;
}

.QRCode {
  display: flex;
  justify-content: center;
}

.am-drawer-sidebar {
  padding-top: 60px;
}

.ant-btn.ant-btn-primary {
  background: #f1c40f;
  border-color: #f39c12;

  &:active,
  &:focus,
  &:hover {
    background: darken(#f1c40f, 3);
    border-color: #f39c12;
  }
}

.havas {
  width: 110px;
  position: absolute;
  right: 25px;
  top: 40px;

  img {
    width: 100%;
  }
}

.am-drawer-content {
  z-index: 1;
}

.am-drawer-sidebar {
  .am-list-line {
    button {
      background: none;
      border: none;
      padding: 0;
      width: 100%;
      text-align: left;
    }
  }
}
