.Match {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 40px;

  &__image {
    width: 25%;
    align-self: center;

    img {
      width: 100%;
    }
  }

  &__date {
    font-weight: bold;
    text-align: center;
    align-self: center;
  }

  &__buttons {
    display: flex;
    width: 100%;
    flex-direction: column;

    &__button {
      margin-bottom: 10px;
      min-height: 44px;
      line-height: 44px;

      &--joined {
        color: #FFF;
        background: #f1c40f;
      }

      &--join,
      &--qr {
        color: #FFF;
        background: #2980b9;
      }
    }
  }

  &__join-button {
  }

  &__joined-button {
    background: darkgreen;
    color: #FFF;
  }

  &__supporters {
    font-weight: bold;

    span {
      font-style: italic;
      font-weight: normal;
    }

    &--limit-reached {
      color: #e74c3c;

      &--adult {
        p:first-child {
          color: #e74c3c;
        }
      }

      &--child {
        p:last-child {
          color: #e74c3c;
        }
      }
    }
  }

  &__button-container {
    flex-wrap: wrap;

    .Match__joined-button,
    .Match__join-button {
      min-height: 44px;
      line-height: 44px;
      margin: 10px 0;
    }

    .Match__joined-button {
      flex: 0 50%;

      &--health-check {
        background: none;
        color: black;
      }
    }

    .Match__join-button {
      width: 100%;
    }
  }
}


.MatchListItem {
  h4 {
    font-weight: 700;

    span {
      font-weight: normal;
    }
  }
}
