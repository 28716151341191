.Kom-je-kijken__login {
  h1 {
    font-weight: 900;
    font-size: 2rem;
    margin-bottom: 0;

    span {
      color: #f1c40f;
    }

  }

  h1 + p {
    font-size: 1rem;
  }

  h2 {
    font-weight: 900;
  }

  &__content {
    margin-bottom: 100px;

    &--signup {
      margin-bottom: 50px;
    }
  }
}


.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0;
  margin: 0;
  background: #e8e9e9;

  &__form {
    border-radius: 10px;
    width: auto;
    padding: 50px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
  }
}
