.Kom-je-kijken__MatchesTable {
  &--max-reached {
    background: #e6e6e6;

    &:hover {
      td.ant-table-column-sort,
      td {
        background: lighten(#e6e6e6, 10) !important;


      }
    }

    td.ant-table-column-sort {
      background: #e6e6e6 !important;
    }
  }
}
